import { useRawStFetch } from '@st/api/composables/useRawStFetch'
import type { ResponseError } from '@st/openapi-tools'
import type { paths } from '@st/api/paths'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useExchangeDataStore } from '../stores/useExchangeDataStore'

interface ExchangeInitParams {
  cryptoAmount: MaybeRef<string>
  fiatAmount: MaybeRef<string>
}

type ExchangeInitReturnError =
  | ResponseError<paths, '/deposit/calypso/init', 'post'>
  | ResponseError<paths, '/deposit/moonpay/get-signed-url', 'post'>

interface ExchangeInitReturn {
  exchangeInit: () => Promise<{
    data: { link: string }
    error?: ExchangeInitReturnError
  }>
}

export function useExchangeInit({
  cryptoAmount,
  fiatAmount,
}: ExchangeInitParams): ExchangeInitReturn {
  const url = useRequestURL()
  const stFetch = useRawStFetch()
  const exchangeDataStore = useExchangeDataStore()
  const { settings } = storeToRefs(useSettingsStore())
  const {
    networkId,
    selectedFiatCurrency,
    currencyId,
    selectedMethod,
    exchangeMethods,
    selectedProvider,
  } = storeToRefs(exchangeDataStore)

  const selectedMethodName = computed(() => {
    const foundMethod = exchangeMethods.value?.find(
      (method) => method.fixedRateId === selectedMethod.value?.fixedRateId,
    )
    if (!foundMethod) return ''

    return foundMethod.methodName
  })

  async function exchangeInit() {
    if (selectedProvider.value === 'calypso') {
      const { data, error } = await stFetch('/deposit/calypso/init', {
        method: 'post',
        body: {
          amount: toValue(cryptoAmount),
          currencyId: currencyId.value,
          networkId: networkId.value,
          rateId: selectedMethod.value?.fixedRateId ?? '',
          fiatCurrency: selectedFiatCurrency.value?.title ?? '',
          fiatAmount: toValue(fiatAmount),
          fiatMethod: selectedMethodName.value ?? '',
        },
      })

      return { data: { link: data?.calypsoDepositLink }, error }
    }

    const redirectUrl = `${
      url.origin
    }/?modal=exchangeStatus&provider=moonpay&status=success&currency=usdt&amount=${toValue(
      cryptoAmount,
    )}`

    const { data, error } = await stFetch('/deposit/moonpay/get-signed-url', {
      method: 'post',
      body: {
        targetCurrencyAmount: toValue(cryptoAmount),
        currencyId: currencyId.value,
        networkId: networkId.value,
        targetCurrencyCode: settings.value?.frontMoonpayTargetCurrency ?? '',
        baseCurrencyCode: selectedFiatCurrency.value?.title as string,
        redirectUrl,
        theme: 'Betone',
      },
    })

    return { data: { link: data?.url }, error }
  }

  return {
    exchangeInit,
  }
}
