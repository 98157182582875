<template>
  <form
    class="first-step"
    :class="wrapperClasses"
    @submit.prevent="handleSubmit"
  >
    <div v-if="platform === 'desktop'" class="back-button-wrapper">
      <StButton
        :label="t('payments.withdrawal.goBack')"
        size="l"
        type="text-only"
        icon="chevron-left"
        is-left-icon
        @click="goBack"
      />
    </div>
    <h2 v-if="platform === 'desktop'">
      {{ t('payments.deposit.deposit') }} ({{ selectedMethod?.methodName }})
    </h2>
    <StDrawerHeader v-if="platform === 'mobile'" with-border-bottom>
      <StButton
        size="m"
        type="text-secondary"
        icon="chevron-left"
        is-left-icon
        @click="goBack"
      />
      <h2>
        {{ t('payments.deposit.deposit') }} ({{ selectedMethod?.methodName }})
      </h2>
    </StDrawerHeader>
    <div v-if="isVerification" class="prepare-verification">
      <StSpinner class="spinner" size="32" />
      <div class="text-content">
        <h2>{{ t('payments.deposit.verificationTitle') }}</h2>
        <span>{{ t('payments.deposit.verificationSubtitle') }}</span>
      </div>
    </div>
    <div v-else class="content">
      <div class="content-wrapper">
        <Inputs
          v-model:crypto-amount="cryptoAmount"
          v-model:fiat-amount="fiatAmount"
          v-model:crypto-amount-error="cryptoAmountError"
          v-model:fiat-amount-error="fiatAmountError"
          :is-disabled="isLoading"
        />
      </div>
      <Providers />
      <div class="button-wrapper">
        <StButton
          :size="platform === 'desktop' ? 'xl' : 'l'"
          :label="
            cryptoAmount
              ? t('payments.buyCrypto.buy2')
              : t('payments.buyCrypto.buy')
          "
          class="buy-button"
          :loading="isLoading"
          :disabled="isDisabledButton"
          data-t="buy-button-jzi9"
          submit
        />
        <div class="buy-crypto-tooltip-wrapper">
          <BuyCryptoTooltip />
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { isValidNumber } from '@st/utils'
import { useExchangeDataStore } from '@st/payments/stores/useExchangeDataStore'
import BuyCryptoTooltip from '../../BuyCryptoTooltip/BuyCryptoTooltip.vue'
import Inputs from './Inputs.vue'
import Providers from './Providers.vue'
import { useLimits } from './useLimits'
import { useExchangeInit } from '../../../composables/useExchangeInit'

const emit = defineEmits<{
  goToNextStep: [value: { frameUrl: string }]
  goBack: []
}>()

const { platform } = usePlatform()

const { t } = useI18n()

const dataStore = useExchangeDataStore()
const { selectedMethod, currencyId, selectedCurrency, selectedProvider } =
  storeToRefs(dataStore)

const { format: formatCrypto } = useCryptoFormatter({
  currency: computed(() => selectedCurrency.value?.code),
})

const cryptoAmount = ref('100')
const fiatAmount = ref('')

const isLoading = ref(false)
const fiatAmountError = ref('')
const cryptoAmountError = ref('')

const isDisabledButton = computed(() => {
  if (!isValidNumber(cryptoAmount.value)) return true

  if (fiatAmountError.value || cryptoAmountError.value) return true

  return !cryptoAmount.value
})

const toast = useToast()
const isVerification = ref(false)

const limits = useLimits({
  selectedMethod,
  currencyId,
  selectedProvider,
})

function validate(): boolean {
  if (
    !cryptoAmount.value ||
    new Decimal(cryptoAmount.value.trim()).greaterThan(limits.value.maxLimit)
  ) {
    cryptoAmountError.value = t('payments.buyCrypto.maxAmountError', {
      maxAmount: formatCrypto(limits.value.maxLimit),
    })

    return false
  }

  if (new Decimal(cryptoAmount.value.trim()).lessThan(limits.value.minLimit)) {
    cryptoAmountError.value = t('payments.buyCrypto.minAmountError', {
      minAmount: formatCrypto(limits.value.minLimit),
    })
    return false
  }

  return true
}

function getErrorMessage(code: string): string {
  switch (code) {
    case 'LAST_USER_VERIFICATION_IS_REJECTED':
      return t('payments.deposit.buyForbidden')
    case 'ACTIVE_USER_VERIFICATION_ALREADY_EXISTS':
      return t('payments.kycVerification.verificationRequired')
    default:
      return t('payments.buyCrypto.somethingWentWrong')
  }
}
const { exchangeInit } = useExchangeInit({ cryptoAmount, fiatAmount })

async function handleSubmit() {
  if (!selectedMethod.value) return

  const isValid = validate()
  if (!isValid) return

  isLoading.value = true

  const { data, error } = await exchangeInit()

  isLoading.value = false
  if (error) {
    if (error.error === 'USER_VERIFICATION_CREATED') {
      isVerification.value = true

      return
    }

    toast.open({
      label: getErrorMessage(error.error),
      type: 'negative',
    })
    return
  }

  emit('goToNextStep', { frameUrl: data.link })
}

const io = useSocket()
const router = useRouter()

function handleRedirectToVerification(
  verificationStatus:
    | 'userWait'
    | 'adminWait'
    | 'adminInProgress'
    | 'approved'
    | 'rejected'
    | 'canceled'
    | 'failed',
) {
  if (verificationStatus === 'rejected' || verificationStatus === 'userWait') {
    router.replace({
      query: {
        modal: 'paymentsKyc',
        type: verificationStatus,
      },
    })
  }

  if (verificationStatus === 'approved') {
    isVerification.value = false
    handleSubmit()
  }
}

io.on('userVerificationStatus', (verificationStatusData) => {
  handleRedirectToVerification(verificationStatusData.status)
})

function goBack() {
  emit('goBack')
}

const wrapperClasses = computed(() => ({
  [platform.value]: platform,
  verification: isVerification.value,
}))
</script>

<style scoped>
.first-step {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  height: 100%;
  min-height: 748px;
  padding: var(--spacing-300);
}

h2 {
  margin: var(--spacing-100) 0 var(--spacing-300);
  padding: 0;
  font: var(--desktop-text-2xl-semibold);
}

.back-button-wrapper {
  line-height: 0;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
  justify-content: flex-start;

  height: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  margin: var(--spacing-050) 0 var(--spacing-100);
}

.amount-input-select {
  margin-top: var(--spacing-100);
}

.currency {
  color: var(--text-tertiary);
}

.disclaimer {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;

  margin-top: var(--spacing-100);
  margin-bottom: var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;

  p {
    margin: 0;
    padding: 0;
  }

  span {
    color: var(--text-primary);
  }
}

.disclaimer-title {
  color: var(--text-primary);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.buy-button {
  width: 100%;
}

/* stylelint-disable */
.prepare-verification {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-250);
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  text-align: center;

  .spinner {
    color: var(--icon-primary);
  }

  .text-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-050);
    align-items: center;
  }

  h2 {
    margin: 0;
    padding: 0;
    font: var(--desktop-text-xl-semibold);
  }

  span {
    font: var(--desktop-text-md-medium);
    color: var(--text-tertiary);
  }
}
/* stylelint-enable */

.buy-crypto-tooltip-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacing-250) 0 0;
}

.first-step.verification {
  justify-content: flex-start;

  .prepare-verification {
    flex-grow: 1;
  }
}

.first-step.mobile {
  gap: 0;
  height: 100%;
  min-height: auto;
  padding: 0;

  :deep(.base) {
    padding-left: var(--spacing-075);
  }

  h2 {
    margin: 0;
    font: var(--mobile-title-2-semibold);
  }

  .content {
    overflow: hidden;
    overflow-y: auto;
    gap: var(--spacing-150);
    padding: var(--spacing-200) var(--spacing-200) 0;
  }

  .button-wrapper {
    flex-grow: 1;
    padding: var(--spacing-050) 0 var(--spacing-200);
  }

  .amount-buttons {
    margin-top: calc(-1 * var(--spacing-100));
  }

  .disclaimer {
    margin-bottom: 0;
    padding-bottom: 0;
    font: var(--mobile-caption-1-regular);
  }

  .content-wrapper {
    margin-top: 0;
  }

  .info-block {
    .text {
      font: var(--mobile-caption-1-regular);
    }
  }

  .buy-crypto-tooltip-wrapper {
    margin: var(--spacing-200) 0 0;
  }
}
</style>
