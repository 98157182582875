<template>
  <div class="ton-connect">
    <div class="separator">
      <div class="line"></div>
      <div class="text">{{ t('payments.tonConnect.or') }}</div>
      <div class="line"></div>
    </div>
    <div v-if="isConnected" class="connect-button-wrapper">
      <StButton
        :label="
          t('payments.tonConnect.useWallet', {
            address: truncateAddress(walletAddress),
          })
        "
        icon="c-crypto-icon-ton"
        is-left-icon
        type="gray"
        @click="connect"
      />
      <div class="promt">
        <StIcon name="circle-clock" size="12" class="circle-clock-icon" />
        <div class="promt-text">
          <I18nT keypath="payments.tonConnect.promtText">
            <template #text>
              <span>{{ t('payments.tonConnect.promtTextBold') }} </span>
            </template>
          </I18nT>
        </div>
      </div>
    </div>

    <StButton
      v-else
      :label="t('payments.tonConnect.buttonTitle')"
      icon="c-crypto-icon-ton"
      is-left-icon
      type="gray"
      @click="connect"
    />
  </div>
</template>

<script setup lang="ts">
import { useTonConnect } from '../../../composables/useTonConnect'
import { truncateAddress } from '../../../helpers'

const emit = defineEmits<{
  handleConnect: []
}>()

const { t } = useI18n()
const { openModal, isConnected, walletAddress } = useTonConnect()

function connect() {
  if (!isConnected.value) {
    openModal()
  } else {
    emit('handleConnect')
  }
}
</script>

<style scoped>
.ton-connect {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.separator {
  display: flex;
  align-items: center;
  padding: var(--spacing-100) 0;

  .text {
    padding: 0 var(--spacing-250);
    font: var(--mobile-caption-1-regular);
    color: var(--text-secondary);
  }

  .line {
    flex-grow: 1;
    border-top: var(--border-width-light) solid var(--border-primary);
  }
}

.connect-button-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;

  background-color: var(--background-base);
  border-radius: var(--border-radius-150);
}

.promt {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-150);

  span {
    color: var(--text-primary);
  }
}

.mobile {
  .promt {
    flex-shrink: 0;
    padding: var(--spacing-125) var(--spacing-150);
  }

  .circle-clock-icon {
    flex-shrink: 0;
  }

  .promt-text {
    text-align: left;
  }
}
</style>
