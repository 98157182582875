<template>
  <div class="deposit" :class="platform" data-t="deposit">
    <NavigationTabs
      v-if="current === 'methods'"
      :type="platform"
      data-t="deposit-navigation-tabs-07my"
    />
    <DepositMethods
      v-if="current === 'methods'"
      :type="platform"
      @handle-currency-selected="handleCurrencySelected"
      @go-to-buy-crypto="goToBuyCrypto"
    />
    <DepositForm
      v-if="current === 'form'"
      :type="platform"
      :selected-currency-id="selectedCurrencyId"
      @back="goToPrevious"
      @handle-connect="handleTonConnect"
    />
    <ClientOnly v-if="isTonConnectEnabled">
      <TonConnect
        v-if="current === 'tonConnect'"
        :deposit-address="depositAddress"
        @back="goTo('form')"
      />
      <StDrawer v-model="isTonConnectDrawerOpened" :full-screen="false">
        <StDrawerHeader
          :title="t('payments.tonConnect.depositFromWallet')"
          disable-router-back
          with-border-bottom
          @close="isTonConnectDrawerOpened = false"
        />
        <TonConnect
          :deposit-address="depositAddress"
          @back="isTonConnectDrawerOpened = false"
        />
      </StDrawer>
    </ClientOnly>
    <BuyCrypto v-if="current === 'buyCrypto'" @go-back="goTo('methods')" />
  </div>
</template>

<script setup lang="ts">
import { useTonConnectEnabled } from '../../composables/useTonConnectEnabled'
import BuyCrypto from '../BuyCrypto/BuyCrypto.vue'

const props = withDefaults(defineProps<{ platform?: 'desktop' | 'mobile' }>(), {
  platform: 'desktop',
})

const { t } = useI18n()

const isTonConnectEnabled = useTonConnectEnabled()

const { goTo, current, goToPrevious } = useStepper([
  'methods',
  'form',
  'tonConnect',
  'buyCrypto',
])

const selectedCurrencyId = ref()

function handleCurrencySelected(id: number) {
  selectedCurrencyId.value = id
  goTo('form')
}

const isTonConnectDrawerOpened = ref(false)
const depositAddress = ref('')
function handleTonConnect(address: string) {
  depositAddress.value = address

  if (props.platform === 'desktop') {
    goTo('tonConnect')
  } else {
    isTonConnectDrawerOpened.value = true
  }
}

function goToBuyCrypto() {
  goTo('buyCrypto')
}
</script>

<style scoped>
.deposit {
  display: flex;
  flex-direction: column;
  max-height: 748px;
}

.deposit.mobile {
  width: 100%;
  height: 100%;
  min-height: auto;
  max-height: 100%;
  padding-top: 0;
}
</style>
