import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import type { FiatCurrency, ExchangeMethod } from '../interfaces'
import { useCalypsoInit } from './useCalypsoInit'
import { useMoonpayInit } from './useMoonpayInit'

interface InitExchangeDataParams {
  currencyId: Ref<number>
  networkId: Ref<number>
}

interface InitExchangeDataReturn {
  fiatCurrenciesList: ComputedRef<FiatCurrency[]>
  fetchExchangeMethods: (currency: FiatCurrency) => Promise<ExchangeMethod[]>
  isFiatCurrenciesLoading: ComputedRef<boolean>
  fetchCurrencyList: () => Promise<void>
}

export function useInitExchangeData(
  params: InitExchangeDataParams,
): InitExchangeDataReturn {
  const { settings } = storeToRefs(useSettingsStore())

  const isMoonpayEnabled = settings.value?.frontMoonpayEnabled

  const {
    fetchExchangeMethods: fetchCalypsoExchangeMethods,
    fiatCurrenciesList: calypsoFiatCurrenciesList,
    isFiatCurrenciesLoading: isCalypsoFiatCurrenciesLoading,
    fetchCurrencyList: fetchCalypsoCurrencyList,
  } = useCalypsoInit(params)

  if (!isMoonpayEnabled) {
    return {
      fetchExchangeMethods: fetchCalypsoExchangeMethods,
      fiatCurrenciesList: calypsoFiatCurrenciesList,
      isFiatCurrenciesLoading: isCalypsoFiatCurrenciesLoading,
      fetchCurrencyList: fetchCalypsoCurrencyList,
    }
  }

  const {
    fetchExchangeMethods: fetchMoonpayExchangeMethods,
    fiatCurrenciesList: moonpayFiatCurrenciesList,
    isFiatCurrenciesLoading: isMoonpayFiatCurrenciesLoading,
    fetchCurrencyList: fetchMoonpayCurrencyList,
  } = useMoonpayInit()

  function mergeFiatCurrecnies(arr1: FiatCurrency[], arr2: FiatCurrency[]) {
    if (
      isMoonpayEnabled &&
      (isMoonpayFiatCurrenciesLoading.value ||
        isCalypsoFiatCurrenciesLoading.value)
    )
      return []

    const formattedArray = [...arr1]
    arr2.forEach((currency) => {
      const foundCurrency = formattedArray.find(
        (curr) => curr.title === currency.title,
      )
      if (foundCurrency) {
        const provider = foundCurrency.providers[0]
        foundCurrency.providers.push(
          provider === 'calypso' ? 'moonpay' : 'calypso',
        )
      } else {
        formattedArray.push(currency)
      }
    })

    return formattedArray
  }

  async function fetchCurrencyList() {
    await Promise.all([fetchCalypsoCurrencyList(), fetchMoonpayCurrencyList()])
  }

  function mergeEchangeMethodsCurrecnies(
    arr1: ExchangeMethod[],
    arr2: ExchangeMethod[],
  ) {
    const formattedArray = [...arr1]
    arr2.forEach((method) => {
      const foundMethod = formattedArray.find(
        (mth) => mth.methodName === method.methodName,
      )

      if (foundMethod) {
        foundMethod.providers.push(method.providers[0])
      } else {
        formattedArray.push(method)
      }
    })

    return formattedArray
  }

  async function fetchExchangeMethods(currency: FiatCurrency) {
    const [calypsoExchangeMethods, moonpayExchangeMethods] = await Promise.all([
      fetchCalypsoExchangeMethods(currency),
      fetchMoonpayExchangeMethods(currency),
    ])

    return [
      ...mergeEchangeMethodsCurrecnies(
        calypsoExchangeMethods,
        moonpayExchangeMethods,
      ),
    ]
  }

  const isFiatCurrenciesLoading = computed(
    () =>
      isCalypsoFiatCurrenciesLoading.value ||
      isMoonpayFiatCurrenciesLoading.value,
  )

  return {
    fetchExchangeMethods,
    isFiatCurrenciesLoading,
    fetchCurrencyList,
    fiatCurrenciesList: computed(() => [
      ...mergeFiatCurrecnies(
        calypsoFiatCurrenciesList.value,
        moonpayFiatCurrenciesList.value,
      ),
    ]),
  }
}
