<template>
  <form
    class="ton-connect"
    :class="platform"
    data-t="ton-connect"
    @submit.prevent="handleSubmit"
  >
    <div v-if="platform === 'desktop'" class="go-back">
      <StButton
        :label="t('payments.deposit.goBack')"
        size="l"
        type="text-only"
        icon="chevron-left"
        is-left-icon
        @click="emit('back')"
      />
    </div>
    <div class="content">
      <h2 v-if="platform === 'desktop'">
        {{ t('payments.tonConnect.depositFromWallet') }}
      </h2>
      <div class="wallet">
        <img
          src="./assets/wallet.png"
          alt="wallet"
          :width="platform === 'desktop' ? '40' : '32'"
          :height="platform === 'desktop' ? '40' : '32'"
        />
        <div class="address">
          <div class="label">{{ t('payments.tonConnect.wallet') }}</div>
          <div clas="value">
            {{ truncateAddress(walletAddress) }}
          </div>
        </div>
        <StButton
          :label="t('payments.tonConnect.disable')"
          :size="platform === 'desktop' ? 'l' : 'm'"
          type="gray"
          @click="disconnectWallet"
        />
      </div>
      <StInput
        v-bind="amount.componentBindings"
        :label="t('payments.tonConnect.sum')"
        :size="platform === 'desktop' ? 'l' : 'm'"
        autofocus
        inputmode="decimal"
        :placeholder="
          t('payments.tonConnect.placeholder', { min: format(limit.min) })
        "
        @update:model-value="onDecimalInputUpdate"
      >
        <template #postfix>
          <div class="input-postfix">TON</div>
        </template>
      </StInput>
      <div class="button-wrapper">
        <StButton submit :label="t('payments.tonConnect.deposit')" />
        <div class="disclaimer">
          {{ t('payments.tonConnect.disclaimer', { address: depositAddress }) }}
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { isValidNumber } from '@st/utils'
import Decimal from '@st/decimal'
import { required, useForm } from '@st/validate'
import { useTonConnect } from '../../composables/useTonConnect'
import { truncateAddress } from '../../helpers'
import { useTariffsStore } from '../../stores/useTariffsStore'

const props = defineProps<{
  depositAddress: string
}>()

const emit = defineEmits<{
  back: []
}>()

const { t } = useI18n()
const { platform } = usePlatform()
const { walletAddress, sendTransaction, disconnect } = useTonConnect()
const { findLimit } = useTariffsStore()

const limit = computed(() => {
  const foundLimit = findLimit({
    currencyId: 34,
    networkId: 34,
    operationType: 'deposit',
  })

  return {
    min: foundLimit?.minAmount ?? '0',
    max: foundLimit?.maxAmount,
  }
})

const { format } = useCryptoFormatter()

const {
  fields: { amount },
  validate,
  isValid,
  values,
} = useForm({
  fieldsSchema: {
    amount: {
      initialValue: '',
      validators: [
        {
          rule: required,
          errorMessage: t('payments.tonConnect.amountIsRequired'),
        },
        {
          rule: isValidNumber,
          errorMessage: t('payments.tonConnect.amountIsInvalid'),
        },
        {
          rule: (value) =>
            isValidNumber(value) &&
            (!limit.value.min ||
              new Decimal(value).greaterThanOrEqualTo(limit.value.min)),
          errorMessage: () =>
            t('payments.tonConnect.amountIsBelowMinLimit', {
              min: format(limit.value.min),
            }),
        },
        {
          rule: (value) =>
            isValidNumber(value) &&
            (!limit.value.max ||
              new Decimal(value).lessThanOrEqualTo(limit.value.max)),
          errorMessage: () =>
            t('payments.tonConnect.amountIsAboveMaxLimit', {
              max: format(limit.value.max ?? '0'),
            }),
        },
      ],
    },
  },
})

function handleSubmit() {
  validate()
  if (!isValid.value) return

  const transaction = {
    validUntil: Math.floor(Date.now() / 1000) + 180,
    messages: [
      {
        address: props.depositAddress,
        amount: new Decimal(values.value.amount).mul(1000000000).toString(),
      },
    ],
  }

  sendTransaction(transaction)
}

async function disconnectWallet() {
  await disconnect()
  emit('back')
}

function onDecimalInputUpdate() {
  amount.value = amount.value.replace(',', '.')
}
</script>

<style scoped>
.ton-connect {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-height: 748px;
  padding: var(--spacing-300);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-300);
}

h2 {
  margin: 0;
  padding: 0;
  font: var(--desktop-text-2xl-semibold);
}

.wallet {
  display: flex;
  gap: var(--spacing-200, 16px);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-150, 12px) var(--spacing-200, 16px);

  background: var(--background-primary);
  border-radius: var(--border-radius-200);
  box-shadow: 0 0 80px -8px #1d1d2a;

  .address {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .label {
    font: var(--desktop-text-sm-medium);
    color: var(--text-tertiary);
  }

  .value {
    font: var(--desktop-text-md-medium);
    color: var(--text-primary);
  }

  img {
    border-radius: var(--border-radius-200);
  }
}

.input-postfix {
  padding-right: var(--spacing-150);
  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-150);
  justify-content: flex-end;

  width: 100%;
  height: 100%;
}

.disclaimer {
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
  text-align: center;
}

.ton-connect.mobile {
  min-height: auto;
  padding: var(--spacing-200);

  .content {
    padding: 0;
  }

  .wallet {
    padding: var(--spacing-100) var(--spacing-150);
  }

  .disclaimer {
    font: var(--mobile-caption-1-regular);
  }
}
</style>
